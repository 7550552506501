import { css } from '@emotion/react'
import { Dialog, Text, spacing } from '@orus.eu/pharaoh'
import { memo } from 'react'

export const UnsavedChangesDialog = memo<{ reset: () => void; proceed: () => void }>(function UnsavedChangesDialog({
  reset,
  proceed,
}) {
  return (
    <Dialog
      title="Êtes-vous sûr de vouloir quitter ?"
      style="danger"
      size="medium"
      isBackoffice={true}
      primaryActionLabel="Quitter sans sauvegarder"
      onPrimaryAction={proceed}
      secondaryActionLabel="Annuler"
      onSecondaryAction={reset}
      onClose={reset}
    >
      <div
        css={css`
          margin: ${spacing[60]} 0;
          display: flex;
          flex-direction: column;
          gap: ${spacing[60]};
        `}
      >
        <Text variant="body1">
          Vous n’avez pas encore sauvegardé vos changements. Si vous quittez maintenant, toutes les modifications seront
          perdues.
        </Text>
        <Text variant="body1">Voulez-vous continuer sans sauvegarder ?</Text>
      </div>
    </Dialog>
  )
})
