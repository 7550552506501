import type { StateChangeNotification } from '@orus.eu/backend/src/views/subscriptions/subscription-state-triggers-applier'
import { FlexSpacedContainer, LegacyDialog, spacing, useDialogVisibility } from '@orus.eu/pharaoh'
import { memo, useEffect } from 'react'

export const PreventUpdatingContractDialog = memo<{
  stateChangeNotifications: StateChangeNotification[]
}>(function PreventUpdatingContractDialog({ stateChangeNotifications }) {
  const {
    show: showPreventUpdatingContractDialog,
    hide: hidePreventUpdatingContractDialog,
    visible: isPreventUpdatingContractDialogVisible,
  } = useDialogVisibility('prevent-updating-contract')

  /**
   * Reasons to open the dialog, converted to a string to serve as a dependency to the effect
   * to avoid unwanted dialog re-opening when the reasons to display the dialog are the same but
   * it's a different instance of array providing them.
   */
  const reasonsToOpenDialog = stateChangeNotifications
    .filter((message) => stateChangeNotificationPreventUpdatingContract.has(message))
    .join(',')

  useEffect(() => {
    if (reasonsToOpenDialog.length > 0) {
      showPreventUpdatingContractDialog()
    }
  }, [reasonsToOpenDialog, showPreventUpdatingContractDialog])

  return isPreventUpdatingContractDialogVisible ? (
    <LegacyDialog
      title="Créez un nouveau contrat"
      style="problem"
      variant="backoffice"
      secondaryActionLabel="Fermer"
      onSecondaryAction={hidePreventUpdatingContractDialog}
      onClose={hidePreventUpdatingContractDialog}
    >
      <FlexSpacedContainer margin={`${spacing[60]} 0`} padding="0">
        Le changement que vous proposez nécessite un nouveau contrat. <br />
        Un avenant ne peut modifier que les détails du contrat en restant avec le même produit et une activité proche
      </FlexSpacedContainer>
    </LegacyDialog>
  ) : (
    <></>
  )
})

const stateChangeNotificationPreventUpdatingContract = new Set<StateChangeNotification>([
  'contract_products_not_matching_new_activity',
  'at_least_one_product_required',
  'product_selection_contract_mismatch',
])
